/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
// UI lib components
import { Container } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import ContactUs from '../page-components/ContactUs';

/* -------------------------------------------------------------------------- */
/*                             privacy policy page                            */
/* -------------------------------------------------------------------------- */
function ContactUsPage() {
  /* ---------------------------------- HOOKS --------------------------------- */

  // Localization
  const { t } = useTranslation();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <PageLayout>
      <Seo title={t('title')} />

      <Container>
        <ContactUs />
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["ContactUs", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default ContactUsPage;
