/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Styles
import './index.scss';
/* -------------------------------------------------------------------------- */
/*                               Implementation                               */
/* -------------------------------------------------------------------------- */
function ContactUs() {
  /* ---------------------------------- HOOKS --------------------------------- */

  // Localization
  const { t } = useTranslation();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Container className="contact-us-container">
      <Row>
        <Col sm={4}>
          <h1>{t('title')}</h1>
        </Col>
      </Row>
      <Row sm={4}>
        <Col md={6}>
          <form
            action="https://formspree.io/f/mdovegdd"
            id="form"
            method="POST"
          >
            <div className="form-control">
              <label htmlFor="name">{t('formFields.name')}</label>
              <input
                name="name"
                type="name"
                id="name"
                placeholder={t('formFields.placeholders.name')}
                required
              />
            </div>

            <div className="form-control">
              <label htmlFor="email">{t('formFields.email')}</label>
              <input
                name="email"
                type="email"
                id="email"
                placeholder={t('formFields.placeholders.email')}
                required
              />
            </div>

            <div className="form-control">
              <label htmlFor="subject">{t('formFields.subject')}</label>
              <input
                name="subject"
                type="subject"
                id="subject"
                placeholder={t('formFields.placeholders.subject')}
                required
              />
            </div>

            <div className="form-control">
              <label htmlFor="message">{t('formFields.message')}</label>
              <textarea
                id="message"
                name="message"
                cols="30"
                rows="10"
                placeholder={t('formFields.placeholders.message')}
                required
              />
            </div>
            <input
              type="submit"
              value={t('formFields.send')}
              className="submit-btn"
            />
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["PrivacyPolicy", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ContactUs;
